import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Text from '../Text'

const UppercaseLabel = ({labelText, className}) => {
  const labelClass = classnames('fb-uppercase-label', className)

  return (
    <Text size='xsmall' className={labelClass}>
      {labelText}
    </Text>
  )
}

UppercaseLabel.propTypes = {
  /**
   * Label text
   */
  labelText: PropTypes.string.isRequired,
  /**
   * Optional additional classes
   */
  className: PropTypes.string
}

export default UppercaseLabel
