import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

class FieldBrandIcon extends React.Component {
  render () {
    const { color, width, className } = this.props

    const svgClass = classnames(
      {'fb-brand-icon--color-brand': color === 'brand'},
      {'fb-brand-icon--color-accent': color === 'accent'},
      {'fb-brand-icon--color-black': color === 'black'},
      {'fb-brand-icon--color-white': color === 'white'},
      className
    )

    return (
      <svg viewBox='0 0 6 12' xmlns='http://www.w3.org/2000/svg' width={width} className={svgClass} aria-hidden='true'><path d='M3.446 0L0 12h2.186L5.631 0z' /></svg>
    )
  }
}

FieldBrandIcon.propTypes = {
  /**
   * Color variant.
   */
  color: PropTypes.oneOf(['brand', 'accent', 'black', 'white']),
  /**
   * Width in pixels, e.g. '30px'.
   */
  width: PropTypes.string,
  /**
   * Optional additional classes
   */
  className: PropTypes.string
}

FieldBrandIcon.defaultProps = {
  color: 'brand',
  width: '12px'
}

export default FieldBrandIcon
