import React from 'react'
import Text from '../Text'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import FieldBrandIcon from '../../icons/FieldBrandIcon'
import UppercaseLabel from '../UppercaseLabel'
import Author from '../Author'

const TextBlock = ({
  date,
  author,
  image,
  label,
  headline,
  bodyText,
  textColumns,
  isConnectedPanel,
  nextBlockTransparent
}) => {
  const bodyHtml = bodyText && bodyText.childMarkdownRemark
    ? bodyText.childMarkdownRemark.html
    : ''
  const bodyLength = bodyHtml.length
  const isShortBody = bodyLength < 500

  return (
    <div
      className={classnames(
        'fb-text-block',
        {'fb-text-block--two-col': textColumns === 'two'},
        {'fb-text-block--short-text': isShortBody},
        {'fb-text-block--connected': isConnectedPanel},
        {'fb-text-block--with-transparency': nextBlockTransparent}
      )}
    >
      {(headline || label) && (
        <div className='fb-text-block__header'>
          {label && (
            <UppercaseLabel labelText={label} className='fb-text-block__label' />

          )}
          {author && <Author name={author} image={image} label='posted by' date={date} />}
          {headline &&
            <div className='fb-text-block__headline'>
              <Text.Heading tag='h1' size='xlarge' className='fb-text-block__heading'>
                {headline}
              </Text.Heading>
              <FieldBrandIcon color='black'className='fb-text-block__heading-icon' />
            </div>
          }
        </div>
      )}
      {bodyHtml && (
        <Text
          size='body'
          className='fb-text-block__body'
          dangerouslySetInnerHTML={{__html: bodyHtml}}
        />
      )}
    </div>
  )
}

TextBlock.propTypes = {
  /**
   * Text body, can contain HTML tags.
   */
  bodyText: PropTypes.object,
  /**
   * Optional small uppercase label above the headline.
   */
  label: PropTypes.string,
  /**
   * This will display as large left-aligned headline.
   */
  headline: PropTypes.string
}

export default TextBlock
